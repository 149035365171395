<template>
  <OrderDeliverUpdate :options="options" :orderId="orderId" />
</template>

<script>
import OrderDeliverUpdate from "./components/OrderDeliverUpdate.vue";
export default {
  props: { orderId: String },
  components: { OrderDeliverUpdate },
  data() {
    return {
      options: {
        title: "บันทึกการส่งสินค้า",
        btnFillText: "กรอกจำนวนค้างส่ง",
        labelInputText: "บันทึกส่งเพิ่ม",
        confirmText: "บันทึกข้อมูลส่งสินค้า",
        apiPath: "ship",
      },
    };
  },
};
</script>